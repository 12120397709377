import db from '@/db'
import axios from 'axios'
import { useCalendarEventsStore } from '@/stores/CalendarEventsStore'
import { useCalendarCategoriesAndActivitiesStore } from '@/stores/CalendarCategoriesAndActivitiesStore'
import { useCalendarRegisterStore } from '@/stores/CalendarRegisterStore'
import { useLocalStore } from '@/stores/LocalStore'
import { storeToRefs } from 'pinia'
import { v4 as uuid } from 'uuid'

const api = import.meta.env.VITE_API

const calendarCategoriesAndActivitiesStore = useCalendarCategoriesAndActivitiesStore()

const calendarRegisterStore = useCalendarRegisterStore()

const localStore = useLocalStore()
const { currentUser, synch } = storeToRefs(localStore)

const calendarEventsStore = useCalendarEventsStore()

const syncCategoriesAndActivities = async (lastSynchTimestamp) => {
  // await calendarCategoriesAndActivitiesStore.fetchData()
  if (lastSynchTimestamp === 0) {
    await calendarCategoriesAndActivitiesStore.fetchData()
  } else {
    await calendarCategoriesAndActivitiesStore.updateData(lastSynchTimestamp)
  }

  console.log('All categories and activities sinchronized.')
}

const syncEvents = async (lastSynchTimestamp, lastSynchDate) => {
  // const events = await db.events.toArray()
  const events = await db.events.where('changed').above(lastSynchDate).toArray()
  console.log(`${events.length} events fetched from local DB. Sending to remote server.`)

  const EventPromises = events.map(async (event) => {
    const eventRegisters = await db.eventRegisterLink
    .where('eCode')
    .equals(event.code)
    .toArray()
    .then(results => results.map(result => result.rCode))

    const eventData  = {
      ... event,
      registers: eventRegisters
    }

    // Send the processed event to the server
    return axios.post(`${api}/remote/event`, eventData)
  })

  // Use Promise.all to wait for all requests to finish
  Promise.all(EventPromises)
    .then(() => console.log('events sent. Fetching events from remote server.'))
    .catch((error) => console.error('Error uploading events:', error))

  const eventResponse = await axios.get(`${api}/remote/events/${lastSynchTimestamp}`)
  const remoteEvents = eventResponse.data
  
  console.log(`${remoteEvents.length} events fetched from remote server. Saving to local DB.`)

  await db.transaction('rw', 'events', 'eventRegisterLink', async () => {
    for (const e of remoteEvents) {
      const event = {
        code: e.code,
        _id: e._id,
        description: e.description,
        categoryCode: e.category,
        activityCode: e.activity,
        start: new Date(e.start),
        end: new Date(e.end),
        farmCode: e.farm.code,
        created: new Date(e.created),
        changed: new Date(e.changed)
      }

      // Add or update the event in the local db
      await db.events.put(event)

      // Delete old register links associated with this event code and wait for completion
      await db.eventRegisterLink.where('eCode').equals(e.code).delete()

      // Add new register links for this event
      for (const register of e.registers) {
        const erl = {
          code: uuid(),
          eCode: e.code,
          rCode: register
        }
        await db.eventRegisterLink.add(erl)
      }
    }
  })

  // await calendarEventsStore.getEvents()
  console.log('All events saved.')
}

const syncRegisters = async (lastSynchTimestamp, lastSynchDate) => {
  // const registers = await db.registers.toArray()
  const registers = await db.registers.where('changed').above(lastSynchDate).toArray()
  console.log(`${registers.length} registers fetched from local DB. Sending to remote server.`)

  const RegisterPromises = registers.map(async (register) => {
    const RegisterData  = {
      ... register
    }

    // Send the processed register to the server
    return axios.post(`${api}/remote/register`, RegisterData)
  })

  // Use Promise.all to wait for all requests to finish
  Promise.all(RegisterPromises)
    .then(() => console.log('registers sent. Fetching registers from remote server.'))
    .catch((error) => console.error('Error uploading registers:', error))

  const registerResponse = await axios.get(`${api}/remote/registers/${lastSynchTimestamp}`)
  const remoteRegisters = registerResponse.data
  console.log(`${remoteRegisters.length} registers fetched from remote server. Saving to local DB.`)

  await db.transaction('rw', 'registers', async () => {
    for (const r of remoteRegisters) {
      const register = {
        code: r.code,
        _id: r._id,
        name: r.name,
        branch: r.branch,
        qualificator: r.qualificator,
        active: r.active,
        visible: r.visible,
        dateOfBirth: new Date(r.dateOfBirth),
        farmCode: r.farm.code,
        created: new Date(r.created),
        changed: new Date(r.changed)
      }

      // Add or update the register in the local db
      await db.registers.put(register)
    }
  })

  calendarRegisterStore.loadData()
  console.log('All registers saved.')
}

const syncFarms = async (lastSynchTimestamp, lastSynchDate) => {
  const farms = await db.farm.where('changed').above(lastSynchDate).toArray()
  console.log(`${farms.length} farms fetched from local DB. Sending to remote server.`)

  const FarmPromises = farms.map(async (farm) => {
    const FarmData  = {
      ... farm
    }

    // Send the processed farm to the server
    return axios.post(`${api}/remote/farm`, FarmData)
  })

  // Use Promise.all to wait for all requests to finish
  Promise.all(FarmPromises)
    .then(() => console.log('farms sent. Fetching farms from remote server.'))
    .catch((error) => console.error('Error uploading farms:', error))

  const farmResponse = await axios.get(`${api}/remote/farms/${lastSynchTimestamp}`)
  const remoteFarms = farmResponse.data

  console.log(`${remoteFarms.length} farms fetched from remote server. Saving to local DB.`)

  await db.transaction('rw', 'farm', async () => {
    for (const f of remoteFarms) {
      const farm = {
        code: f.code,
        _id: f._id,
        name: f.name,
        active: f.active,
        city: f.city,
        state: f.state,
        created: new Date(f.created),
        changed: new Date(f.changed)
      }

      // Add or update the farm in the local db
      await db.farm.put(farm)
    }
  })

  console.log('All farms saved.')
}

export async function syncAll(lastSynchTimestamp, lastSynchDate) {
  await syncCategoriesAndActivities(lastSynchTimestamp)
  await syncFarms(lastSynchTimestamp, lastSynchDate)
  await syncRegisters(lastSynchTimestamp, lastSynchDate)
  await syncEvents(lastSynchTimestamp, lastSynchDate)
}
