import { defineStore } from 'pinia'
import db from '@/db'
import { useCalendarEventsStore } from '@/stores/CalendarEventsStore'
import { useCalendarNameStore } from '@/stores/CalendarNameStore'
import { storeToRefs } from 'pinia'

export const useCalendarRegisterStore = defineStore('calendarRegister', {
  state: () => ({
    piquetes: [],
    batches: [],
    currentMonthRegistersCode: new Set()
  }),

  actions: {
    async loadData() {
      try {
        const calendarNameStore = useCalendarNameStore()
        const { farm } = storeToRefs(calendarNameStore)

        const calendarEvents = useCalendarEventsStore()
        const { events } = storeToRefs(calendarEvents)
        
        const eventCodes = events.value.map(event => event.id)

        const [registers, eventLinks] = await Promise.all([
          db.registers.filter(reg => isRegisterFromFarm(reg, farm.value)).toArray(),
          db.eventRegisterLink.where('eCode').anyOf(eventCodes).toArray()
        ])

        this.currentMonthRegistersCode = new Set(eventLinks.map(erl => erl.rCode))

        registers.forEach(item => {
          const targetArray = item.branch === 'vegetal' ? this.piquetes : this.batches
          if (!targetArray.some(existingItem => existingItem.code === item.code)) {
            targetArray.push(item)
          }
        })
      } catch (error) {
        console.error('Error loading data:', error)
      }
    },
    async editItem(itemCode, newItem, type) {
      try {
        await db.registers.where('code').equals(itemCode).modify(newItem)

        const targetArray = type === 'piquet' ? this.piquetes : this.batches
        const itemIndex = targetArray.findIndex(item => item.code === itemCode)

        if (itemIndex !== -1) {
          targetArray[itemIndex] = { ...targetArray[itemIndex], ...newItem }
        }
      } catch (error) {
        console.error(`Error editing ${type}:`, error)
      }
    },
    async editPiquet(piquetCode, newPiquet) {
      await this.editItem(piquetCode, newPiquet, 'piquet')
    },
    async editBatch(batchCode, newBatch) {
      await this.editItem(batchCode, newBatch, 'batch')
    }
  },

  getters: {
    getActiveRegisters(state) {
      return [
        ...state.piquetes.filter(p => p.active),
        ...state.batches.filter(b => b.active)
      ]
    },
    getVisibleRegisters(state) {
      return [
        ...state.piquetes.filter(p => p.visible),
        ...state.batches.filter(b => b.visible)
      ]
    },
    currentMonthRegisters(state) {
      const allRegisters = [...state.piquetes, ...state.batches]
      return allRegisters.filter(register => state.currentMonthRegistersCode.has(register.code))
    }
  }
})

// Helper Functions

function isRegisterFromFarm(register, farm) {
  // Check if farm is provided and is not an empty object
  if (!farm || Object.keys(farm).length === 0) return false

  // Compare properties of register.farm and farm
  return (
    register.farmCode === farm.code
  )
}
