<template>
  <div class="mx-2">
    <v-dialog persistent max-width="600px" :transition="transition" :fullscreen="xs">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" class="text-none text-body-1" @click="filter">
          <v-icon class="mr-1"> mdi-filter-menu-outline </v-icon>
          <v-list-item-title v-if="!smAndDown"> Filtro </v-list-item-title>
        </v-btn>
      </template>
      <template v-slot:default="{ isActive }">
        <v-card style="height: 500px">
          <!-- TABS -->
          <v-tabs fixed-tabs v-model="activeTab" slider-color="black" bg-color="#009688">
            <v-tab v-for="(tab, index) in tabs" :key="index" :value="index">
              <v-icon :icon="tab.icon"></v-icon>
              {{ tab.name }}
            </v-tab>
          </v-tabs>
          <v-card-text
            v-if="availablePiquetes.length === 0 && availableBatches.length === 0"
          >
            Nenhum evento encontrado...
          </v-card-text>

          <!-- PIQUETES -->
          <v-card-text v-if="tabs[activeTab] && tabs[activeTab].name === 'Piquetes'">
            <v-row align="center" justify="center">
              <v-col class="text-center">
                <v-btn block variant="text" @click="toogleAllPiquetes()">
                  <v-icon class="ma-2" :color="filtersSelected.length > 0 ? 'teal' : ''">
                    {{ piquetIcon }}
                  </v-icon>
                  <v-list-item-title>Todos</v-list-item-title>
                </v-btn>
                <v-divider class="ma-2"></v-divider>
              </v-col>
            </v-row>
            <v-container class="overflow-y-auto" style="height: 300px">
              <v-row id="scroll-target">
                <v-col cols="auto" class="pr-0">
                  <v-sheet class="pa-0 ma-0">
                    <v-list>
                      <v-list-item
                        v-for="piquet in availablePiquetes"
                        :key="piquet"
                        class="px-0"
                      >
                        <v-checkbox
                          v-model="piquetesSelected"
                          @input="toogleFilter()"
                          :label="piquet"
                          :value="piquet"
                          hide-details
                        />
                      </v-list-item>
                    </v-list>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <!-- LOTES -->
          <v-card-text v-if="tabs[activeTab] && tabs[activeTab].name === 'Lotes'">
            <v-row align="center" justify="center">
              <v-col class="text-center">
                <v-btn block variant="text" @click="toogleAllBatches()">
                  <v-icon class="ma-2" :color="filtersSelected.length > 0 ? 'teal' : ''">
                    {{ batchIcon }}
                  </v-icon>
                  <v-list-item-title> Todos </v-list-item-title>
                </v-btn>
                <v-divider class="ma-2"></v-divider>
              </v-col>
            </v-row>
            <v-container class="overflow-y-auto" style="height: 300px">
              <v-row id="scroll-target">
                <v-col cols="auto" class="pr-0">
                  <v-sheet class="pa-0 ma-0">
                    <v-list>
                      <v-list-item
                        v-for="batch in availableBatches"
                        :key="batch"
                        class="px-0"
                      >
                        <v-checkbox
                          v-model="batchesSelected"
                          @input="toogleFilter()"
                          :label="batch"
                          :value="batch"
                          hide-details
                        />
                      </v-list-item>
                    </v-list>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              variant="text"
              prepend-icon="mdi-close-box-outline"
              @click="isActive.value = false"
            >
              Fechar
            </v-btn>
          </template>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue"
import { useDisplay } from "vuetify"
import db from "@/db"
import { useActivitiesTimelineStore } from "@/stores/ActivitiesTimelineStore"
import { storeToRefs } from "pinia"

const activitiesTimelineStore = useActivitiesTimelineStore()
const { timelineEvents } = storeToRefs(activitiesTimelineStore)

const { xs } = useDisplay()
const { smAndDown } = useDisplay()

const activeTab = ref(0)
const tabs = ref([])
const tabsSet = ref(new Set())

const currentMonthRegisters = ref(new Set())
const visibleRegisters = ref(new Set())

const filtersSelected = ref([])
const piquetesSelected = ref([])
const batchesSelected = ref([])

const selectedRegister = ref("")

const currentMonthEvents = ref([])
const availablePiquetes = ref([])
const availableBatches = ref([])

onMounted(async () => {
  await setVisibleRegisters()
  tabs.value = []
  tabsSet.value.clear()
  await getCurrentMonthEvents()
  await getVisibleRegisters()
  await getAvailablePiquetes()
  await getAvailableBatches()
  tabs.value = Array.from(tabsSet.value)
  // toogleFilter()
  filtersSelected.value = availablePiquetes.value.concat(availableBatches.value)
})

watch(activeTab, () => console.log(activeTab.value))

const filter = async () => {
  tabs.value = []
  tabsSet.value.clear()
  await getCurrentMonthEvents()
  await getVisibleRegisters()
  await getAvailablePiquetes()
  await getAvailableBatches()
  tabs.value = Array.from(tabsSet.value)
  toogleFilter()
}

const transition = computed(() => {
  if (xs.value) {
    return "dialog-bottom-transition"
  }
  return undefined
})

const getCurrentMonthEvents = async () => {
  const codes = timelineEvents.value.map((item) => item.code)

  currentMonthEvents.value = codes
}

const setVisibleRegisters = async () => {
  await db.registers
    .toCollection()
    .modify({
      visible: true,
    })
    .catch(db.ModifyError, (error) => {
      console.error(error.failures.length + " items failed to modify")
    })
    .catch((error) => {
      console.error("Error setting visibility value: " + error)
    })
}

const getVisibleRegisters = async () => {
  visibleRegisters.value.clear()
  currentMonthRegisters.value.clear()

  await db.eventRegisterLink
    .where("eCode")
    .anyOf(currentMonthEvents.value)
    .toArray()
    .then((result) => {
      result.forEach((erl) => {
        currentMonthRegisters.value.add(erl.rCode)
      })
    })
    .catch((error) => {
      console.error("Error gettinng current month registers:", error)
    })

  await db.registers
    .where("code")
    .anyOf(currentMonthRegisters.value)
    .toArray()
    .then((result) => {
      result.forEach((reg) => {
        if (reg.visible) {
          visibleRegisters.value.add(reg.name)
        }
      })
    })
    .catch((error) => {
      console.error("Error gettinng visible registers:", error)
    })
}

const getAvailablePiquetes = async () => {
  piquetesSelected.value = []
  const currentMonthPiquetes = new Set()

  await db.registers.each((register) => {
    if (register.branch === "vegetal") {
      availablePiquetes.value.push(register.code)
    }
  })

  await db.eventRegisterLink
    .where("eCode")
    .anyOf(currentMonthEvents.value)
    .and((erl) => availablePiquetes.value.includes(erl.rCode))
    .toArray()
    .then((result) => {
      result.forEach((erl) => {
        currentMonthPiquetes.add(erl.rCode)
      })
    })

  availablePiquetes.value = []

  await db.registers
    .where("code")
    .anyOf(currentMonthPiquetes)
    .toArray()
    .then((result) => {
      result.forEach((register) => {
        availablePiquetes.value.push(register.name)
      })
    })

  availablePiquetes.value.forEach((piquet) => {
    if (visibleRegisters.value.has(piquet)) {
      piquetesSelected.value.push(piquet)
    }
  })

  if (availablePiquetes.value.length > 0) {
    const tabObject = {
      name: "Piquetes",
      icon: "mdi-sprout-outline",
    }
    tabsSet.value.add(tabObject)
  }
}

const getAvailableBatches = async () => {
  batchesSelected.value = []
  const currentMonthBatches = new Set()

  await db.registers.each((register) => {
    if (register.branch === "animal") {
      availableBatches.value.push(register.code)
    }
  })

  await db.eventRegisterLink
    .where("eCode")
    .anyOf(currentMonthEvents.value)
    .and((erl) => availableBatches.value.includes(erl.rCode))
    .toArray()
    .then((result) => {
      result.forEach((erl) => {
        currentMonthBatches.add(erl.rCode)
      })
    })

  availableBatches.value = []

  await db.registers
    .where("code")
    .anyOf(currentMonthBatches)
    .toArray()
    .then((result) => {
      result.forEach((register) => {
        availableBatches.value.push(register.name)
      })
    })

  availableBatches.value.forEach((piquet) => {
    if (visibleRegisters.value.has(piquet)) {
      batchesSelected.value.push(piquet)
    }
  })

  if (availableBatches.value.length > 0) {
    const tabObject = {
      name: "Lotes",
      icon: "mdi-cow",
    }
    tabsSet.value.add(tabObject)
  }
}

const toogleAllPiquetes = () => {
  if (selectedAllPiquetes.value || selectedSomePiquetes.value) {
    piquetesSelected.value = []
  } else if (selectedNoPiquetes.value) {
    piquetesSelected.value = availablePiquetes.value
  }
  toogleFilter()
}

const toogleAllBatches = () => {
  if (selectedAllBatches.value || selectedSomeBatches.value) {
    batchesSelected.value = []
  } else if (selectedNoBatches.value) {
    batchesSelected.value = availableBatches.value
  }
  toogleFilter()
}

const toogleFilter = async () => {
  filtersSelected.value = []
  filtersSelected.value = piquetesSelected.value.concat(batchesSelected.value)

  timelineEvents.value.forEach((item) => {
    // Divide os registros em uma lista e verifica se algum existe no primeiro array
    const itemRegisters = item.registers.split(", ").map((register) => register.trim())
    const isVisible = itemRegisters.some((register) =>
      filtersSelected.value.includes(register)
    )

    // Atualiza o atributo 'visible' com base na verificação
    item.visible = isVisible
  })
}

const selectedAllPiquetes = computed(() => {
  return piquetesSelected.value.length === availablePiquetes.value.length
})

const selectedSomePiquetes = computed(() => {
  return piquetesSelected.value.length > 0 && !selectedAllPiquetes.value
})

const selectedNoPiquetes = computed(() => {
  return piquetesSelected.value.length === 0
})

const piquetIcon = computed(() => {
  if (selectedAllPiquetes.value) return "mdi-close-box"
  if (selectedSomePiquetes.value) return "mdi-minus-box"
  return "mdi-checkbox-blank-outline"
})

const selectedAllBatches = computed(() => {
  return batchesSelected.value.length === availableBatches.value.length
})

const selectedSomeBatches = computed(() => {
  return batchesSelected.value.length > 0 && !selectedAllBatches.value
})

const selectedNoBatches = computed(() => {
  return batchesSelected.value.length === 0
})

const batchIcon = computed(() => {
  if (selectedAllBatches.value) return "mdi-close-box"
  if (selectedSomeBatches.value) return "mdi-minus-box"
  return "mdi-checkbox-blank-outline"
})
</script>
