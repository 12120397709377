<template>
  <div>
    <v-dialog
      persistent
      max-width="600px"
      :transition="transition"
      :fullscreen="xs"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          size="x-large"
          icon="mdi-tune"
          variant="text"
          class="mx-0 px-0"
        > </v-btn>
      </template>
      <template v-slot:default="{ isActive }">
        <v-card style="height: 500px">
          <v-toolbar color="#009688">
            <v-toolbar-title class="text-center"> Ajustes </v-toolbar-title>
          </v-toolbar>

          <v-tabs
            fixed-tabs
            v-model="activeTab"
          >
            <v-tab>
              <v-list-item-title>Piquetes</v-list-item-title>
            </v-tab>
            <v-tab>
              <v-list-item-title>Lotes</v-list-item-title>
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>

          <v-card-text v-if="activeTab === 0">
            <v-container
              class="overflow-y-auto"
              style="height: 300px"
            >
              <p v-if="piquetes.length === 0">
                {{ "Nenhum piquete encontrado..." }}
              </p>
              <v-list>
                <v-list-item
                  v-for="(piquet, index) in piquetes"
                  :key="index"
                  align="center"
                  justify="center"
                >
                  <v-row no-gutters align="center">
                    <v-col>
                      <v-sheet class="ma-2 pa-2">
                        <p :class="piquet.active ? 'text-high-emphasis' : 'text-disabled'">
                          {{ piquet.name }}
                        </p>
                      </v-sheet>
                    </v-col>
                    <v-col>
                      <v-btn-toggle
                        v-model="toggleSettingOption"
                        variant="outlined"
                        divided
                      >
                        <v-btn icon="mdi-pencil-outline" @click="openEditDialog(piquet)"></v-btn>
                        <v-btn :icon="piquet.active ? 'mdi-eye-outline' : 'mdi-eye-closed'" @click="togglePiquetActive(piquet)"></v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-container>
          </v-card-text>

          <v-card-text v-if="activeTab === 1">
            <v-container
              class="overflow-y-auto"
              style="height: 300px"
            >
              <p v-if="batches.length === 0">
                {{ "Nenhum lote encontrado..." }}
              </p>
              <v-list>
                <v-list-item
                  v-for="(batch, index) in batches"
                  :key="index"
                  align="center"
                  justify="center"
                >
                  <v-row no-gutters align="center">
                    <v-col>
                      <v-sheet class="ma-2 pa-2">
                        <p :class="batch.active ? 'text-high-emphasis' : 'text-disabled'">
                          {{ batch.name }}
                        </p>
                      </v-sheet>
                    </v-col>
                    <v-col>
                      <v-btn-toggle
                        v-model="toggleSettingOption"
                        variant="outlined"
                        divided
                      >
                        <v-btn icon="mdi-pencil-outline" @click="openEditDialog(batch)"></v-btn>
                        <v-btn :icon="batch.active ? 'mdi-eye-outline' : 'mdi-eye-closed'" @click="toggleBatchActive(batch)"></v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-container>
          </v-card-text>

          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              variant="text"
              prepend-icon="mdi-close-box-outline"
              @click="isActive.value = false"
            >
              Fechar
            </v-btn>
          </template>
        </v-card>
        <CalendarEditDialog v-model="editDialog" :selectedRegister="selectedRegister" @close="closeEditDialog()"/>
      </template>
    </v-dialog>
    <CalendarSnackbar v-model="settingsSnackbar" :text="snackbarText"/>
    <CalendarSnackbar v-model="visibilitySnackbar" :text="snackbarText"/>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useDisplay } from 'vuetify'
import CalendarEditDialog from './CalendarEditDialog.vue'
import CalendarSnackbar from './CalendarSnackbar.vue'
import db from '@/db'
import { useCalendarRegisterStore } from '@/stores/CalendarRegisterStore'
import { storeToRefs } from 'pinia'

const calendarRegisterStore = useCalendarRegisterStore()
const { piquetes, batches } = storeToRefs(calendarRegisterStore)

const editDialog = ref(false)
const activeTab = ref(0)
const toggleSettingOption = ref(null)
const selectedRegister = ref({})

const { xs } = useDisplay()

const settingsSnackbar = ref(false)
const visibilitySnackbar = ref(false)
const snackbarText = ref('')

onMounted(() => {
})

const transition = computed(() => {
  if (xs.value) {
    return 'dialog-bottom-transition'
  }
  return undefined
})

const togglePiquetActive = async (piquet) => {
  await db.registers.where('code').equals(piquet.code).modify(reg => {
    reg.active = !reg.active
    piquetes.value.find(register => register.name === piquet.name).active = reg.active
    if (reg.active) {
      snackbarText.value = piquet.name + ' ativado'
    } else {
      snackbarText.value = piquet.name + ' desativado'
    }
    visibilitySnackbar.value = true
  })
}

const toggleBatchActive = async (batch) => {
  await db.registers.where('code').equals(batch.code).modify(reg => {
    reg.active = !reg.active
    batches.value.find(register => register.name === batch.name).active = reg.active
    if (reg.active) {
      snackbarText.value = batch.name + ' ativado'
    } else {
      snackbarText.value = batch.name + ' desativado'
    }
    visibilitySnackbar.value = true
  })
}

const openEditDialog = (register) => {
  editDialog.value = true
  selectedRegister.value = register
}

const closeEditDialog = () => {
  editDialog.value = false
}

</script>
