<template>
  <div>
    <v-dialog
      v-model="reportDialog"
      persistent
      scrollable
      transition="dialog-bottom-transition"
      fullscreen
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          color="red"
          variant="flat"
          class="white--text mr-4"
          @click="reportDialog = true"
        >
          <v-icon> mdi-file-document </v-icon>
          <v-list-item-title class="ml-2"> Relatório </v-list-item-title>
        </v-btn>
      </template>
      <template v-slot:default="{ isActive }">
        <v-toolbar color="#009688">
          <v-btn
            class="white--text"
            icon="mdi-arrow-left"
            @click="isActive.value = false"
          ></v-btn>
          <v-toolbar-title class="text-start"> Relatório de atividades </v-toolbar-title>

          <PdfViewer />
        </v-toolbar>

        <v-card>
          <ActivitiesTimeLine />

          <v-card-actions></v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref } from "vue"
import PdfViewer from "./CreatePdf.vue"
import ActivitiesTimeLine from "./ActivitiesTimeLine.vue"

const reportDialog = ref(false)

</script>
