import vfsFonts from "../assets/fonts/vfs_fonts"

export const pdfMakeConfig = (pdfMake) => {
  pdfMake.vfs = vfsFonts
  pdfMake.fonts = {
    Roboto: {
      normal: 'Roboto-Regular.ttf',
      bold: 'Roboto-Medium.ttf',
      italics: 'Roboto-Italic.ttf',
      bolditalics: 'Roboto-MediumItalic.ttf'
    }
  }
}
