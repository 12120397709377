import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import { setupAxiosInterceptors } from '@/plugins/axios'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import vuetify from './plugins/vuetify'
// import VueLocalStorage from 'vue-localstorage'
// import VueLodash from 'vue-lodash'
// import lodash from 'lodash'

import VueMatomo from 'vue-matomo'
import { router, matomoConfig } from './router'
import db, { setupDexieHooks } from './db'

console.log('calendario-de-manejos / remote @ ' + import.meta.env.VITE_STAGE)
console.log('Version: ' + import.meta.env.VITE_VERSION)

Sentry.init({
  Vue: createApp(App),
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: import.meta.env.VITE_VERSION.split('-')[0],
  environment: import.meta.env.VITE_STAGE,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', window.location.hostname, /^\//]
    })
  ],
  tracesSampleRate: 1.0
})

// Create Vue app instance
const app = createApp(App)
// app.use(VueLodash, { name: 'custom', lodash: lodash })
// app.use(VueLocalStorage)
// app.use(VueLocalStorage, {
//   name: 'ls',
//   bind: true
// })

app.use(createPinia())
app.use(vuetify)
app.use(router)
app.use(VueMatomo, matomoConfig)

// Configura interceptors do Axios
setupAxiosInterceptors()

// Setup Dexie hooks after Pinia is ready
setupDexieHooks()

// Provide Dexie database instance globally
app.config.globalProperties.$db = () => db

app.mount('#app')
