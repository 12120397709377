import { defineStore } from 'pinia'

export const useLocalStore = defineStore('local', {
  state: () => ({
    currentUser: {},
    synch: false,
  }),
  actions: {
    getUser() {
      const storedUser = JSON.parse(localStorage.getItem('user'));

      if (storedUser?.authenticated) {
        this.currentUser = storedUser;
      }
    }
  }
});
