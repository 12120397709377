import axios from 'axios'
import { useLocalStore } from '@/stores/LocalStore'
import { storeToRefs } from 'pinia'

export const setupAxiosInterceptors = () => {
  const localStore = useLocalStore()
  const { currentUser } = storeToRefs(localStore)

  axios.interceptors.request.use((config) => {
    if (currentUser.value?.token) {
      config.headers.Authorization = `Bearer ${currentUser.value.token}`
    }
    return config
  }, (error) => {
    return Promise.reject(error)
  })
}
