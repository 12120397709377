import { defineStore } from "pinia"
import db from "@/db"
import { useDate } from 'vuetify'

export const useActivitiesTimelineStore = defineStore("activitiesTimelineStore", {
  state: () => ({
    timelineEvents: [],
    initialDate: null,
    finalDate: null
  }),
  actions: {
    // Fetch events within date range and process them
    async getEvents(adapter) {
      try {
        // const adapter = useDate()
        const sDate = this.initialDate ? this.initialDate : adapter.parseISO('2024-01-01')
        const fDate = this.finalDate ? this.finalDate : adapter.parseISO('2024-12-31')

        if (!sDate || !fDate) {
          console.warn("Datas inválidas")
          return
        }

        const events = await db.events
          .where("start")
          .between(sDate, fDate, true, true)
          .toArray()

        const processedEvents = await Promise.all(events.map(this.processEvent))
        this.timelineEvents = processedEvents
      } catch (error) {
        console.error("Error fetching events:", error)
      }
    },

    // Process a single event and fetch related data
    async processEvent(event) {
      try {
        const [eventRegistersLink, eCategory, eActivity] = await Promise.all([
          db.eventRegisterLink.where("eCode").equals(event.code).toArray(),
          db.categories.where("code").equals(event.categoryCode).first(),
          db.activities.where("code").equals(event.activityCode).first(),
        ])
    
        const registerNames = await this.getRegisterNames(eventRegistersLink)
    
        const startDate = new Date(event.start)
        const endDate = new Date(event.end)
        endDate.setDate(endDate.getDate() - 1) // Ajust end date
    
        return {
          branch: eCategory?.branch || "",
          categoryName: eCategory?.name || "",
          activityName: eActivity?.name || "",
          icon: eCategory?.icon || "mdi-alert",
          color: eCategory?.color || "#000000",
          description: event.description || "",
          code: event.code,
          registers: registerNames,
          start: new Date(startDate).toLocaleDateString("pt-BR"),
          end: new Date(endDate).toLocaleDateString("pt-BR"),
          duration: (endDate - startDate) / (24 * 60 * 60 * 1000),
          visible: true,
        }
      } catch (error) {
        console.error("Error processing event:", error)
        return {}
      }
    },    

    // Fetch register names for a list of register links
    async getRegisterNames(eventRegistersLink) {
      try {
        const registerCodes = eventRegistersLink.map((erl) => erl.rCode)
        const registers = await db.registers
          .where("code")
          .anyOf(registerCodes)
          .toArray()
        return registers.map((reg) => reg.name).join(", ")
      } catch (error) {
        console.error("Error fetching register names:", error)
        return ""
      }
    },
  },
  getters: {
    // Events data for PDF export
    eventsForPDF: (state) => {
      return state.timelineEvents.map((event) => [
        event.start,
        event.end,
        event.categoryName,
        event.activityName,
        event.registers,
        event.description,
      ])
    },
    computedInitialDateFormatted: (state) => {
      const adapter = useDate()
      return adapter.format(state.initialDate, 'keyboardDate') || "01/01/2024"
    },
    computedFinalDateFormatted: (state) => {
      const adapter = useDate()
      return adapter.format(state.finalDate, 'keyboardDate') || "31/12/2024"
    },    
  },
})
