<template>
  <div>
    <v-dialog max-width="600px" v-model="importexport" persistent>
      <template v-slot:default="{ isActive }">
        <v-toolbar color="teal">
          <v-toolbar-title class="text-center mr-4"> Exportar Planilha </v-toolbar-title>
        </v-toolbar>

        <v-card>
          <v-card-text class="mb-4">
            <v-container style="height: 100px">
              <v-row justify="center">
                <v-col align="center" cols="10">
                  Dados históricos serão baixados para {{ title }}
                </v-col>
                <v-col align="center" cols="10">
                  <v-btn
                    color="warning"
                    variant="flat"
                    class="white--text mr-4"
                    size="large"
                    block
                    @click="downloadExcel()"
                  >
                    <v-icon> mdi-file-excel-outline </v-icon>
                    <v-list-item-title class="ml-2"> Baixar planilha </v-list-item-title>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              variant="text"
              prepend-icon="mdi-close-box-outline"
              @click="isActive.value = false"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script setup>
import db from "@/db";
import { useDisplay } from "vuetify";
import { storeToRefs } from "pinia";
import { useCalendarNameStore } from "@/stores/CalendarNameStore";
import { ref } from "vue";
import ExcelJS from "exceljs";

const { xs } = useDisplay();

const calendarNameStore = useCalendarNameStore();
const { title } = storeToRefs(calendarNameStore);

const importexport = defineModel();
const allEvents = ref([]);
const allBatches = ref([]);
const allPiquetes = ref([]);

const data = ref([
  {
    "Data de início": "01/10/2024",
    "Data de término": "10/10/2024",
    Categoria: "example",
    Atividade: "example",
    "Lote / Piquete": "example",
    Descrição: "example",
  },
]);

/**
 * Função para baixar a planilha em formato Excel.
 */
const downloadExcel = async () => {
  importexport.value = false;

  // Executa getEvents para atualizar allEvents antes do download
  await getEvents();

  // Executa getBatches para atualizar allBatches antes do download
  await getBatches();

  // Executa getPiquetes para atualizar allPiquetes antes do download
  await getPiquetes();

  // Objeto para armazenar os dados separados por ano
  const dataByYear = {};

  // Separar os eventos por ano
  allEvents.value.forEach((event) => {
    const year = event["Data de início"].split("/")[2];
    if (!dataByYear[year]) {
      dataByYear[year] = []; // Cria um array para o ano se não existir
    }
    dataByYear[year].push(event);
  });

  // Cria um novo workbook
  const workbook = new ExcelJS.Workbook();

  // Cria a aba "Info" como a primeira aba
  const worksheetInfo = workbook.addWorksheet("Info");
  worksheetInfo.getCell(
    "A1"
  ).value = `Documento gerado em ${new Date().toLocaleDateString(
    "pt-br"
  )} às ${new Date().toLocaleTimeString(
    "pt-br"
  )}, a partir do aplicativo Calendário de Manejos, versão: ${
    import.meta.env.VITE_VERSION
  }`;
  worksheetInfo.getCell("A3").value = `Nome da propriedade: ${title.value}`;
  const explanation = `
    Explicação da Planilha:

    Esta planilha contém o histórico completo de manejo animal e vegetal da propriedade, organizado e separado por anos. Cada ano apresenta os eventos de manejo registrados, dispostos em ordem cronológica.

    - Eventos: Cada evento corresponde a uma ação específica realizada no manejo da propriedade.
    - Categorias e Atividades: Para cada evento, estão listadas as categorias e atividades relacionadas, oferecendo uma visão detalhada do que foi realizado.
    - Lotes/Piquetes: A cada evento, as informações sobre os lotes ou piquetes afetados também são registradas, permitindo acompanhar o manejo em diferentes áreas da propriedade.
  `;
  worksheetInfo.getCell("A5").value = explanation;
  worksheetInfo.getCell("A5").alignment = { wrapText: true };
  worksheetInfo.getColumn(1).width = 120;

  // Cria a aba de piquetes
  const worksheetPiquetes = workbook.addWorksheet("Piquetes");
  if (allPiquetes.value && allPiquetes.value.length > 0) {
    // Adiciona os dados dos lotes, assumindo que allPiquetes.value é um array de objetos
    const columnsBatches = Object.keys(allPiquetes.value[0]).map((key) => ({
      header: key,
      key: key,
      width: 20,
    }));
    worksheetPiquetes.columns = columnsBatches;
    worksheetPiquetes.addRows(allPiquetes.value);

    // Aplica o estilo de negrito nas células do cabeçalho
    worksheetPiquetes.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Ativa a quebra de texto e alinhamento nas células
    worksheetPiquetes.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = { wrapText: true, vertical: "middle" }; // Ativa a quebra de texto
      });
    });
  }

  // Cria a aba de lotes
  const worksheetBatches = workbook.addWorksheet("Lotes");
  if (allBatches.value && allBatches.value.length > 0) {
    // Adiciona os dados dos lotes, assumindo que allBatches.value é um array de objetos
    const columnsBatches = Object.keys(allBatches.value[0]).map((key) => ({
      header: key,
      key: key,
      width: 20,
    }));
    worksheetBatches.columns = columnsBatches;
    worksheetBatches.addRows(allBatches.value);

    // Aplica o estilo de negrito nas células do cabeçalho
    worksheetBatches.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Formata a coluna A como tipo 'Data'
    worksheetBatches.getColumn(1).eachCell((cell) => {
      cell.numFmt = "dd/mm/yyyy";
    });

    // Ativa a quebra de texto e alinhamento nas células
    worksheetBatches.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = { wrapText: true, vertical: "middle" }; // Ativa a quebra de texto
      });
    });
  }

  // Adiciona os eventos para cada ano
  Object.keys(dataByYear).forEach((year) => {
    const worksheetEvents = workbook.addWorksheet(year);

    if (dataByYear[year] && dataByYear[year].length > 0) {
      // Adiciona os dados dos eventos, assumindo que dataByYear[year] é um array de objetos
      const columnsEvents = Object.keys(dataByYear[year][0]).map((key) => ({
        header: key,
        key: key,
        width: 20,
      }));
      worksheetEvents.columns = columnsEvents;
      worksheetEvents.addRows(dataByYear[year]);

      // Aplica o estilo de negrito nas células do cabeçalho
      worksheetEvents.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
      });

      // Formata as colunas A e B como tipo 'Data'
      worksheetEvents.getColumn(1).eachCell((cell) => {
        cell.numFmt = "dd/mm/yyyy";
      });
      worksheetEvents.getColumn(2).eachCell((cell) => {
        cell.numFmt = "dd/mm/yyyy";
      });

      // Ativa a quebra de texto e alinhamento nas células
      worksheetEvents.eachRow((row) => {
        row.eachCell((cell) => {
          cell.alignment = { wrapText: true, vertical: "middle" };
        });
      });
    }
  });

  // Gera o arquivo XLSX e inicia o download
  // Nome do arquivo no formato "nome_da_fazenda-YYYYMMDD-HHMMSS"
  workbook.xlsx.writeBuffer().then((buffer) => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const timestamp = `${year}${month}${day}-${hours}${minutes}${seconds}`;

    const excelFileName = title.value
      .trim() // Remove espaços no início e no final
      .replace(/ /g, "_") // Substitui espaços por _
      .normalize("NFD") // Decompõe caracteres com acentos
      .replace(/[\u0300-\u036f]/g, "") // Remove diacríticos
      .replace(/[^a-zA-Z0-9_]/g, ""); // Remove caracteres inválidos

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Calendario_de_Manejos-${excelFileName}-${timestamp}.xlsx`;
    link.click();
  });
};

/**
 * Função para obter todos os eventos do banco de dados e processá-los.
 * Retorna uma lista de eventos formatados e ordenados por data de início.
 */
const getEvents = async () => {
  let events = [];

  await db.events
    .toArray()
    .then((result) => {
      events = result;
    })
    .catch((error) => {
      console.error("Error getting events:", error);
    });

  const eventPromises = events.map(async (event) => {
    const eventRegistersLink = await db.eventRegisterLink
      .where("eCode")
      .equals(event.code)
      .toArray();

    const registersCodes = eventRegistersLink.map((erl) => erl.rCode);

    let registersNames = [];
    await db.registers
      .where("code")
      .anyOf(registersCodes)
      .toArray()
      .then((result) => {
        registersNames = result.map((reg) => reg.name);
      })
      .catch((error) => {
        console.log("Error getting registers:", error);
      });

    const eCategory = await db.categories
      .where("code")
      .equals(event.categoryCode)
      .toArray();
    const eActivity = await db.activities
      .where("code")
      .equals(event.activityCode)
      .toArray();

    return {
      branch: eCategory[0].branch,
      category: eCategory[0].name,
      activity: eActivity[0].name,
      description: event.description,
      registers: registersNames,
      dateStart: event.start,
      dateEnd: event.end,
      formattedDateStart: event.start.toLocaleDateString("pt-BR"),
    };
  });

  allEvents.value = (await Promise.all(eventPromises)).sort(
    (a, b) => a.dateStart - b.dateStart
  );

  // Após obter os eventos, processa `allEvents` para separar as entradas com múltiplos lotes ou piquetes
  allEvents.value = processEvents(allEvents.value);
};

/**
 * Função para obter todos os piquetes do banco de dados e processá-los.
 * Retorna uma lista de piquetes formatados e ordenados por ordem alfabética,
 * filtrando os registros onde db.registers.branch === "vegetal".
 */
const getPiquetes = async () => {
  let batches = [];

  await db.registers
    .where("branch")
    .equals("vegetal") // Filtra os registros onde o campo 'branch' é igual a 'vegetal'
    .toArray()
    .then((result) => {
      batches = result;
    })
    .catch((error) => {
      console.error("Error getting batches:", error);
    });

  const batchPromises = batches.map(async (batch) => {
    var batchStatus = batch.active;
    if (batchStatus) {
      batchStatus = "Ativo";
    } else {
      batchStatus = "Desativado";
    }
    return {
      Piquete: batch.name,
      Status: batchStatus,
    };
  });

  // Ordena por ordem alfabética
  allPiquetes.value = (await Promise.all(batchPromises)).sort(
    (a, b) => a.dateOfBirth - b.dateOfBirth
  );
};

/**
 * Função para obter todos os lotes do banco de dados e processá-los.
 * Retorna uma lista de lotes formatados e ordenados por data de nascimento,
 * filtrando os registros onde db.registers.branch === "animal".
 */
const getBatches = async () => {
  let batches = [];

  await db.registers
    .where("branch")
    .equals("animal") // Filtra os registros onde o campo 'branch' é igual a 'animal'
    .toArray()
    .then((result) => {
      batches = result;
    })
    .catch((error) => {
      console.error("Error getting batches:", error);
    });

  const batchPromises = batches.map(async (batch) => {
    var batchStatus = batch.active;
    if (batchStatus) {
      batchStatus = "Ativo";
    } else {
      batchStatus = "Desativado";
    }
    return {
      "Data de nascimento": batch.dateOfBirth.toLocaleDateString("pt-BR"),
      Lote: batch.name,
      "Categoria animal": batch.qualificator,
      Status: batchStatus,
      dateOfBirth: batch.dateOfBirth,
    };
  });

  // Ordena por data de nascimento e remove dateOfBirth
  allBatches.value = (await Promise.all(batchPromises))
    .sort((a, b) => a.dateOfBirth - b.dateOfBirth)
    .map(({ dateOfBirth, ...rest }) => rest);
};

/**
 * Função para formatar eventos com múltiplos lotes ou piquetes.
 * São criados registros individuais.
 */
const processEvents = (events) => {
  const processedEvents = [];

  events.forEach((event) => {
    // Subtrai um dia da data de término
    const adjustedDateEnd = new Date(event.dateEnd);
    adjustedDateEnd.setDate(adjustedDateEnd.getDate() - 1);

    if (event.registers.length > 1) {
      // Duplicar a entrada para cada valor em `registers`
      event.registers.forEach((register) => {
        processedEvents.push({
          "Data de início": event.formattedDateStart,
          "Data de término": adjustedDateEnd.toLocaleDateString("pt-BR"),
          Categoria: event.category,
          Atividade: event.activity,
          "Lote / Piquete": register, // Aqui define `registers` como uma string
          Descrição: event.description,
        });
      });
    } else {
      // Se o array `registers` tiver apenas um valor, adiciona o evento como está
      processedEvents.push({
        "Data de início": event.formattedDateStart,
        "Data de término": adjustedDateEnd.toLocaleDateString("pt-BR"),
        Categoria: event.category,
        Atividade: event.activity,
        "Lote / Piquete": event.registers[0], // Muda para string
        Descrição: event.description,
      });
    }
  });

  return processedEvents;
};
</script>
