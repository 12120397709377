<template>
    <v-row justify="center">
      <v-dialog
        v-model="newBatchDialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title class="justify-center">
            <span class="text-h5">Novo Lote</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container>
              <v-row>
                <v-col cols="12"  class="pa-0 ma-0">
                  <v-expand-transition>
                    <v-card flat>
                      <v-col cols="12">
                        <v-text-field
                          v-model="batchName"
                          variant="outlined"
                          hide-details
                          label="Nome"
                          :rules="fieldRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-menu
                          v-model="newBatchMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ props }">
                            <v-text-field
                              v-bind="props"
                              variant="outlined"
                              hide-details
                              v-model="computedNewBatchDateFormatted"
                              label="Data de Nascimento"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            locale="pt-BR"
                            v-model="newBatchDate"
                            no-title
                            @update:modelValue="newBatchMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="selectedQualificator"
                          variant="outlined"
                          hide-details
                          :items="selectedQualificators"
                          label="Qualificador"
                        ></v-select>
                      </v-col>
                    </v-card>
                  </v-expand-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              variant="text"
              @click="hideNewBatchDialog()"
              prepend-icon="mdi-cancel"
            >
              <span>Cancelar</span>
            </v-btn>
            <v-btn
              color="#009688"
              variant="text"
              @click="addNewBatch()"
              prepend-icon="mdi-file-document-plus-outline"
            >
              Registrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <CalendarSnackbar v-model="eventSnackbar" :text="snackbarText"/>
    </v-row>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useDate } from 'vuetify'
import { v4 as uuid } from 'uuid'
import db from '@/db'
import CalendarSnackbar from '../components/CalendarSnackbar.vue'
import { useCalendarRegisterStore } from '@/stores/CalendarRegisterStore'
import { useCalendarNameStore } from '@/stores/CalendarNameStore'
import { storeToRefs } from 'pinia'

const calendarNameStore = useCalendarNameStore()
const { farm } = storeToRefs(calendarNameStore)

const calendarRegisterStore = useCalendarRegisterStore()

const emit = defineEmits(['refresh'])

const newBatchDialog = defineModel()
const batchName = ref('')
const newBatchDate = ref(null)
const newBatchMenu = ref(false)
const selectedQualificators = ref([])
const selectedQualificator = ref('')

const eventSnackbar = ref(false)
const snackbarText = ref('')

const fieldRules = [
  v => !!v || 'Campo obrigatório'
]

const formatDate = (date) => {
  const adapter = useDate()
  if (!date) return null
  return adapter.format(date, 'keyboardDate')
}

const addNewBatch = () => {
  const b = {
    code: uuid(),
    branch: 'animal',
    name: batchName.value,
    dateOfBirth: new Date(newBatchDate.value),
    qualificator: selectedQualificator.value,
    active: true,
    visible: true,
    farmCode: farm.value.code,
    created: new Date(),
    changed: new Date()
  }
  
  db.registers.where('name').equals(b.name).first().then(existingBatch => {
    if (existingBatch) {
      newBatchDialog.value = false
      batchName.value = ''
      newBatchDate.value = null
      selectedQualificator.value = ''
      snackbarText.value = 'Lote já existe!'
      eventSnackbar.value = true
      console.log('Batch already exists')
    } else {
      db.registers.add(b).then(() => {
        calendarRegisterStore.loadData()
        emit('refresh', batchName.value)
        batchName.value = ''
        newBatchDialog.value = false
        newBatchDate.value = null
        selectedQualificator.value = ''
        snackbarText.value = 'Lote criado com sucesso'
        eventSnackbar.value = true
      }).catch((error) => {
        console.log('Error adding batch:', error)
      })
    }
  }).catch(error => {
    console.log(error)
  })
}

const hideNewBatchDialog = () => {
  newBatchDialog.value = false
}

const batchQualificator = () => {
  const batchDate = new Date(newBatchDate.value)
  const currentDate = new Date()

  const batchAge = (currentDate.getFullYear() - batchDate.getFullYear()) * 12 +
        (currentDate.getMonth() - batchDate.getMonth())

  if (batchAge > 12) {
    selectedQualificators.value = qualificators.slice(0, 3)
  } else if (batchAge > 9 && batchAge <= 12) {
    selectedQualificators.value = qualificators.slice(6, 7)
  } else if (batchAge > 6 && batchAge <= 9) {
    selectedQualificators.value = qualificators.slice(5, 6)
  } else if (batchAge > 3 && batchAge <= 6) {
    selectedQualificators.value = qualificators.slice(4, 5)
  } else {
    selectedQualificators.value = qualificators.slice(3, 4)
  }

  if (selectedQualificators.value.length === 1) {
    selectedQualificator.value = selectedQualificators.value[0]
  }
}

watch(newBatchDate, () => {
  batchQualificator()
})

const computedNewBatchDateFormatted = computed(() => formatDate(newBatchDate.value))

const qualificators = ['Sobreano', 'Touro', 'Matriz', 'Recém-nascidos', 'Maternal', 'Desmama', 'Pós-desmama']

</script>
