import Dexie from 'dexie'
import { useCalendarRegisterStore } from '@/stores/CalendarRegisterStore'
import { useLocalStore } from '@/stores/LocalStore'
import { storeToRefs } from 'pinia'

const db = new Dexie('calendario-de-manejos')

db.version(40).stores({
  categories: 'code, active, author, name, icon, branch, color, created, changed',
  activities: 'code, active, author, name, description, recommended, categoryCode, created, changed',
  farm: 'code, _id, active, name, city, state, created, changed',
  lastAccessed: 'code, farmCode',
  events: 'code, _id, categoryCode, activityCode, start, end, description, farmCode, created, changed',
  eventRegisterLink: 'code, eCode, rCode',
  registers: 'code, branch, name, dateOfBirth, qualificator, active, visible, farmCode, created, changed'
})

// Function to set up Dexie hooks
export function setupDexieHooks() {
  const calendarRegisterStore = useCalendarRegisterStore()
  const localStore = useLocalStore();
  const { synch } = storeToRefs(localStore);

  // Farm table
  db.farm.hook('creating', () => { synch.value = true });
  db.farm.hook('updating', () => { synch.value = true });
  db.farm.hook('deleting', () => { synch.value = true });

  // Events table
  db.events.hook('creating', () => { synch.value = true });
  db.events.hook('updating', () => { synch.value = true });
  db.events.hook('deleting', () => { synch.value = true });

  // Registers table
  db.registers.hook('creating', () => { synch.value = true });
  db.registers.hook('updating', () => { synch.value = true });
  db.registers.hook('deleting', () => { synch.value = true });
}

export default db
