<template>
  <v-dialog v-model="pdfDialog">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        color="red"
        size="large"
        variant="flat"
        class="white--text mr-4"
        @click="pdfDialog = true"
      >
        <v-icon v-if="xs" size="large"> mdi-file-pdf-box </v-icon>
        <v-icon v-else> mdi-file-document </v-icon>
        <v-list-item-title v-if="!xs" class="ml-2"> PDF </v-list-item-title>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <span class="headline text-left">{{ pdfFileName }}</span>
        <v-spacer></v-spacer>
        <v-btn
          variant="text"
          icon="mdi-download"
          @click="downloadPdf"
          class="ml-2"
        ></v-btn>
        <v-btn variant="text" icon="mdi-close" @click="closeDialog" class="ml-2"></v-btn>
      </v-card-title>
      <iframe
        v-if="pdfUrl"
        :src="pdfUrl + '#toolbar=0&view=FitH'"
        width="100%"
        height="800px"
        frameborder="0"
      ></iframe>
    </v-card>
    <CalendarLoader v-model="loading" :text="loadingText" />
    <CalendarLoader v-model="downloading" :text="downloadingText" />
  </v-dialog>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useDisplay } from "vuetify";
import { useActivitiesTimelineStore } from "@/stores/ActivitiesTimelineStore";
import { useCalendarNameStore } from "@/stores/CalendarNameStore";
import { getBase64ImageFromUrl } from "@/helpers/imageUtils";
import CalendarLoader from "./CalendarLoader.vue";

import pdfMake from "pdfmake/build/pdfmake";
import { pdfMakeConfig } from "@/config/pdfConfig";

// Carregar as imagens como arquivos
import pastocertoLogo from "../assets/pastocerto_logo.png";
import lojasLogo from "../assets/logos_lojas.png";
import { storeToRefs } from "pinia";

pdfMakeConfig(pdfMake);

const pdfDialog = defineModel();

const { xs } = useDisplay();

const activitiesTimelineStore = useActivitiesTimelineStore()
const { initialDate, finalDate } = storeToRefs(activitiesTimelineStore)

const calendarNameStore = useCalendarNameStore();
const { title } = storeToRefs(calendarNameStore);

const pdfUrl = ref("");
const pastocertoLogoBase64 = ref("");
const lojasLogoBase64 = ref("");
const docDefinition = ref({});

const loading = ref(false);
const loadingText = "Aguarde, carregando o PDF...";

const downloading = ref(false);
const downloadingText = "Aguarde, fazendo o download...";

watch(pdfDialog, async () => {
  if (pdfDialog.value) {
    loading.value = true;
    await loadImagesAndGeneratePdf();
    loading.value = false;
  }
});

const pdfFileName = computed(() => {
  if (loading.value) {
    return "";
  } else {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const timestamp = `${year}${month}${day}-${hours}${minutes}${seconds}`;

    const pdfFileName = title.value
      .trim() // Remove espaços no início e no final
      .replace(/ /g, "_") // Substitui espaços por _
      .normalize("NFD") // Decompõe caracteres com acentos
      .replace(/[\u0300-\u036f]/g, "") // Remove diacríticos
      .replace(/[^a-zA-Z0-9_]/g, ""); // Remove caracteres inválidos

    // Nome do arquivo no formato "nome_da_fazenda-YYYYMMDD-HHMMSS"
    return `Calendario_de_Manejos-${pdfFileName}-${timestamp}.pdf`;
  }
});

// Carregar as imagens em base64 antes de gerar o PDF
const loadImagesAndGeneratePdf = async () => {
  try {
    pastocertoLogoBase64.value = await getBase64ImageFromUrl(pastocertoLogo);
    lojasLogoBase64.value = await getBase64ImageFromUrl(lojasLogo);
    docDefinition.value = getDocDefinition();
    await generatePdf();
  } catch (error) {
    console.error("Erro ao carregar as imagens", error);
  }
};

const generatePdf = async () => {
  pdfMake.createPdf(docDefinition.value).getBlob((blob) => {
    const url = URL.createObjectURL(blob);
    pdfUrl.value = url;
  });
};

const downloadPdf = () => {
  downloading.value = true;

  // Allow the UI to update before running the download process
  setTimeout(() => {
    pdfMake.createPdf(docDefinition.value).download(pdfFileName.value);
    downloading.value = false;
  }, 0);
};

const closeDialog = () => {
  pdfDialog.value = false;
  pdfUrl.value = ""; // Reseta o PDF quando o diálogo é fechado
};

const getDocDefinition = () => {
  return {
    pageSize: "A4", // Define o tamanho da página
    pageOrientation: "landscape", // Define a orientação para paisagem
    header: {
      table: {
        widths: ["10%", "80%", "10%"],
        heights: ["*"],
        body: [
          [
            {
              image: pastocertoLogoBase64.value, // Logo do Pasto Certo
              width: 70,
              height: 70,
              alignment: "left",
              border: [false, false, false, true],
              rowSpan: 2,
            },
            {
              text: "Relatório de atividades de manejo", // Título centralizado
              bold: true,
              alignment: "center",
              fontSize: 24,
              border: [false, false, false, false],
            },
            {
              text: "", // Coluna vazia para alinhamento
              border: [false, false, false, false],
            },
          ],
          [
            {
              text: "", // Coluna vazia para alinhamento
              border: [false, false, false, false],
            },
            {
              image: lojasLogoBase64.value, // Logo das Lojas
              width: 260,
              height: 30,
              alignment: "center",
              margin: [0, 4, 0, 0],
              border: [false, false, false, true],
            },
            {
              text: "", // Coluna vazia para alinhamento
              border: [false, false, false, true],
            },
          ],
        ],
      },
      layout: "noBorders",
      margin: [0, 5, 0, 0],
    },
    content: [
      {
        text: [
          { text: "Propriedade: ", bold: true },
          `${title.value}    `,
          { text: "Período: ", bold: true },
          `${initialDate.value} a ${finalDate.value}`,
        ],
        alignment: "center",
        fontSize: 11,
        margin: [0, 0, 0, 0],
      },
      {
        // Linha de separação
        canvas: [
          {
            type: "line",
            x1: -10,
            y1: 0,
            x2: 1000, // Ajuste conforme a largura da página
            y2: 0,
            lineWidth: 1,
            lineColor: "black",
          },
        ],
        margin: [0, 6, 0, 10], // Margens superior e inferior para espaçar da tabela
      },
      {
        table: {
          headerRows: 1,
          widths: [80, 93, 100, 100, 100, "*"],
          body: [
            [
              { text: "Data de início", style: "tableHeader" },
              { text: "Data de término", style: "tableHeader" },
              { text: "Categoria", style: "tableHeader" },
              { text: "Atividade", style: "tableHeader" },
              { text: "Lotes/Piquetes", style: "tableHeader" },
              { text: "Descrição", style: "tableHeader" },
            ],
            ...activitiesTimelineStore.eventsForPDF,
          ],
        },
        layout: "lightHorizontalLines",
      },
    ],
    styles: {
      font: "Default",
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
    },
    footer: {
      text: [
        {
          alignment: "right",
          text: `Documento gerado em ${new Date().toLocaleDateString(
            "pt-br"
          )} às ${new Date().toLocaleTimeString(
            "pt-br"
          )}, a partir do aplicativo Calendário de Manejos, versão: ${
            import.meta.env.VITE_VERSION
          }\n\n`,
          fontSize: 8,
        },
        {
          alignment: "justify",
          text:
            "Apesar do empenho em fornecer as mais precisas e atualizadas informações, o Calendário de Manejos não se responsabiliza pelas consequências da sua má utilização pelos usuários. As orientações, diagnósticos e sugestões dessa análise não implicam na assunção de quaisquer responsabilidades por danos ou prejuízos decorrentes da aquisição, utilização, manipulação e aplicação de produtos, bem como estão sujeitos a posterior confirmação por profissional legalmente habilitado.",
          fontSize: 6,
        },
      ],
      margin: [5, -5, 5, 0],
    },
    pageMargins: [5, 80, 5, 45], // Margens da página
  };
};
</script>
