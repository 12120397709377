<template>
  <div>
    <v-dialog
      v-model="loading"
      persistent
      max-width="450px"
    >
      <v-card class="pa-5">
        <v-card-title class="justify-center">
          <span class="text-p1 text-wrap mr-5">{{ message }}</span>
          <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
const loading = defineModel()
const props = defineProps(['text'])

const message = props.text
</script>
