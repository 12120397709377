<template>
  <v-row align="center" justify="center" class="ma-0 pa-0">
    <v-col cols="12" class="pa-0">
      <v-card class="mx-auto" elevation="0">
        <v-container fluid class="pa-0">
          <v-row dense>
            <v-col cols="auto" class="pr-0">
              <!--CALENDAR CATEGORIES LIST-->

              <v-list-item class="my-2 pa-0 d-none d-md-flex">
                <template v-slot:prepend>
                  <v-btn
                    size="x-large"
                    color="teal"
                    :icon="
                      collapsedCategories
                        ? 'mdi-chevron-double-right'
                        : 'mdi-chevron-double-left'
                    "
                    variant="text"
                    density="compact"
                    @click="collapsedCategories = !collapsedCategories"
                  >
                  </v-btn>
                </template>

                <v-list-item-title
                  v-if="collapsedCategories == false"
                  class="ml-6 text-h6"
                >
                  Categorias
                </v-list-item-title>
              </v-list-item>

              <v-list class="py-0" slim>
                <v-list-item
                  v-for="(category, index) in categoriesList"
                  :key="index"
                  :value="category"
                  color="teal"
                  link
                  class="pa-0 my-1"
                  :style="{ borderLeft: `4px solid ${category.color}` }"
                  @click="updateCategory(category)"
                >
                  <template v-slot:prepend>
                    <v-badge
                      :modelValue="badgeContentList[index] > 0 ? true : false"
                      color="error"
                      :content="badgeContentList[index]"
                      bordered
                      start
                      class="ml-1"
                    >
                      <v-icon size="x-large" class="ml-1"> {{ category.icon }} </v-icon>
                    </v-badge>
                  </template>

                  <v-list-item-title
                    v-if="collapsedCategories == false"
                    class="d-none d-md-flex pr-4"
                  >
                    {{ category.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>

            <!-- CALENDAR ACTIVITIES LIST -->
            <v-col class="pl-0">
              <div
                v-if="collapsedCategories === true || smAndDown"
                class="py-4 text-white"
                style="background-color: #009688"
              >
                <v-list-item-title class="text-h6 text-wrap">{{
                  category.name
                }}</v-list-item-title>
              </div>

              <!--TABS-->

              <v-tabs
                fixed-tabs
                v-model="activeTab"
                slider-color="black"
                bg-color="teal-lighten-1"
              >
                <v-tab>
                  <v-list-item-title> Recomendadas </v-list-item-title>
                  <v-badge
                    :modelValue="recommendedBadgeContent > 0 ? true : false"
                    color="error"
                    :content="recommendedBadgeContent"
                    inline
                    bordered
                  ></v-badge>
                </v-tab>
                <v-tab>
                  <v-list-item-title> Outras </v-list-item-title>
                </v-tab>
              </v-tabs>

              <!--EXPANSION PANELS-->

              <v-card elevation="0">
                <v-card-text v-if="filteredActivities.length === 0">
                  Nenhuma atividade encontrada...
                </v-card-text>
                <v-list v-else>
                  <v-list-item
                    v-for="(activity, index) in filteredActivities"
                    :key="index"
                    class="px-0"
                  >
                    <span
                      align="start"
                      style="float: left; word-wrap: break-word"
                      class="mr-2 pl-1"
                    >
                      {{ activity.name }}
                    </span>

                    <template v-slot:append>
                      <v-chip
                        size="small"
                        class="mr-1"
                        :color="setChipColor(index)"
                        v-if="
                          activeTab === 0 && (
                            (category.branch === 'vegetal' && piquetes.length !== 0) || 
                            (category.branch !== 'vegetal' && batches.length !== 0)
                          )
                        "
                      >
                        {{
                          (getCategoryRegisters?.registers[index] || 0) +
                          " / " +
                          (category.branch === "vegetal"
                            ? piquetes.length
                            : batches.length)
                        }}
                      </v-chip>
                      <v-btn
                        variant="flat"
                        class="mr-1"
                        icon="mdi-plus-circle-outline"
                        @click="showNewEventDialog(activity)"
                      ></v-btn>
                      <v-btn
                        variant="flat"
                        class="ma-0"
                        icon="mdi-help-circle-outline"
                        @click.stop="showActivityDialog(activity)"
                      ></v-btn>
                    </template>
                  </v-list-item>
                </v-list>
                <v-dialog v-model="activityDescriptionModel" max-width="600px">
                  <v-card>
                    <v-card-title>
                      {{ activityTitle }}
                    </v-card-title>
                    <v-card-text>
                      {{ activityDescription }}
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        variant="text"
                        @click="activityDescriptionModel = false"
                        prepend-icon="mdi-close"
                      >
                        Fechar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card>

              <!--NEW EVENT DIALOG-->
              <CalendarEventDialog
                v-model="newEventDialog"
                :category="category"
                :activity="selectedActivity"
                @refresh="refresh"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
    <CalendarNoInternetError v-model="error" :text="erroMessage" />
    <CalendarSynchLoader v-model="synchLoader" />
    <MessageSnack v-model="snackbar" :text="snackbarText" />
  </v-row>
</template>

<script setup>
import CalendarEventDialog from "../components/CalendarEventDialog.vue"
import CalendarSynchLoader from "./CalendarLoader.vue"
import MessageSnack from "@/components/MessageSnack.vue"
import { ref, watch, computed, onUnmounted } from "vue"
import { useDisplay } from "vuetify"
import db from "@/db"
import CalendarNoInternetError from "./CalendarNoInternetError.vue"
import { useCalendarDateStore } from "@/stores/CalendarDateStore"
import { useCalendarCategoriesAndActivitiesStore } from "@/stores/CalendarCategoriesAndActivitiesStore"
import { storeToRefs } from "pinia"
import { useCalendarRegisterStore } from "@/stores/CalendarRegisterStore"

const props = defineProps(["reload"])

const { smAndDown } = useDisplay()

const recommendedBadgeContent = ref(null)
const activityDescriptionModel = ref(false)
const activityTitle = ref("")
const activityDescription = ref("")
const activeTab = ref(0)
const selectedActivity = ref({})

const newEventDialog = ref(false)

// const totalRegistersVegetal = ref(0)
// const totalRegistersAnimal = ref(0)
const registersByCategory = ref([])

const reload = ref(props.reload)
const erroMessage = ref("")
const error = ref(false)
const synchLoader = ref(false)

const calendarRegisterStore = useCalendarRegisterStore()
const { piquetes, batches } = storeToRefs(calendarRegisterStore)

const calendarCategoriesAndActivitiesStore = useCalendarCategoriesAndActivitiesStore()
const { categoriesList, activitiesList, loaded } = storeToRefs(calendarCategoriesAndActivitiesStore)
const allEmpty = ref(false)

const calendarDateStore = useCalendarDateStore()
const { calendarMonth } = storeToRefs(calendarDateStore)
const calendarYear = calendarDateStore.getYearStr

const badgeContentList = ref([])
const category = ref({})
const icon = ref("")

const collapsedCategories = ref(false)

const snackbar = ref(false)
const snackbarText = ref("")

onUnmounted(() => {
  loaded.value = false
})

const filteredActivities = computed(() => {
  const activities = []

  for (let i = 0; i < activitiesList.value.length; i++) {
    if (
      !allEmpty.value &&
      activitiesList.value[i][0].categoryCode === category.value.code
    ) {
      if (activeTab.value === 0) {
        activitiesList.value[i].forEach((activity) => {
          activity.recommended.forEach((rec) => {
            if (rec.year === calendarYear) {
              if (rec.months.includes(calendarMonth.value)) {
                activities.push(activity)
              }
            }
          })
        })
        return activities
      } else {
        activitiesList.value[i].forEach((activity) => {
          activity.recommended.forEach((rec) => {
            if (rec.year === calendarYear) {
              if (!rec.months.includes(calendarMonth.value)) {
                activities.push(activity)
              }
            }
          })
        })
        return activities
      }
    }
  }
  return activities
})

const getCategoryRegisters = computed(() => {
  const registers = registersByCategory.value.find(
    (cat) => cat.categoryCode === category.value.code
  )
  return registers
})

watch(loaded, async () => {
  if (loaded.value) {
    category.value = categoriesList.value[0]
    icon.value = category.value.icon

    // await getTotalRegisters()
    await getRegistersByActivity()
    await calculateBadgeContent()
    await setBadgeContent()

    synchLoader.value = false
    openSnackbar("Dados carregados e sincronizados com sucesso")
  }
})

watch(calendarMonth, async () => {
  // await getTotalRegisters()
  await getRegistersByActivity()
  await calculateBadgeContent()
  await setBadgeContent()
})

watch(props, async () => {
  reload.value = props.reload
  // await getTotalRegisters()
  await calculateBadgeContent()
  refresh()
})

watch(activitiesList, () => {
  allEmpty.value = activitiesList.value.every((subArray) => subArray.length === 0)
})

watch(category, async () => {
  // await getTotalRegisters()
  await getRegistersByActivity()
  await setBadgeContent()
})

watch(activeTab, async () => {
  // await getTotalRegisters()
  await getRegistersByActivity()
})

const openSnackbar = (message) => {
  snackbarText.value = message
  snackbar.value = true
}

const calculateBadgeContent = async () => {
  // Calculate badge content
  badgeContentList.value = []
  activitiesList.value.forEach((item) => {
    let badgeContentListCount = 0
    item.forEach((activity) => {
      activity.recommended.forEach((rec) => {
        if (rec.year === calendarYear) {
          if (rec.months.includes(calendarMonth.value)) {
            badgeContentListCount++
          }
        }
      })
    })
    badgeContentList.value.push(badgeContentListCount)
  })
}

const showNewEventDialog = (activity) => {
  selectedActivity.value = activity
  newEventDialog.value = true
}

const showActivityDialog = (activity) => {
  activityDescriptionModel.value = true
  activityTitle.value = activity.name
  activityDescription.value = activity.description
}

const getRegistersByActivity = async () => {
  try {
    registersByCategory.value = []

    if (!allEmpty.value) {
      for (const activities of activitiesList.value) {
        const registersByActivity = []
        const categoryObject = categoriesList.value.find(
          (cat) => cat.code === activities[0].categoryCode
        )

        for (const activity of activities) {
          for (const recommendation of activity.recommended) {
            if (
              recommendation.year === calendarYear &&
              recommendation.months.includes(calendarMonth.value)
            ) {
              let total = 0
              const events = await db.events
                .where("categoryCode")
                .equals(categoryObject.code)
                .and((event) => event.activityCode === activity.code)
                .toArray()

              let filteredEvents = []

              if (events.length !== 0) {
                filteredEvents = await events.filter(
                  (event) => event.start.getMonth() + 1 === calendarMonth.value
                )
              }

              if (filteredEvents.length === 0) {
                registersByActivity.push(0)
              } else {
                const rCodes = new Set()
                for (const event of filteredEvents) {
                  const result = await db.eventRegisterLink
                    .where("eCode")
                    .equals(event.code)
                    .toArray()
                    .catch((error) => {
                      console.error("Error getting ERLs: ", error)
                      throw error
                    })

                  result.forEach((erl) => rCodes.add(erl.rCode))
                }
                total += rCodes.size
                registersByActivity.push(total)
              }
            }
          }
        }

        const o = {
          categoryCode: categoryObject.code,
          registers: registersByActivity,
        }

        registersByCategory.value.push(o)
      }
    }
  } catch (error) {
    console.log("Error getting registers by activity: ", error)
    throw error
  }
}

const setChipColor = (index) => {
  const registers = registersByCategory.value.find(
    (cat) => cat.categoryCode === category.value.code
  )
  if (registers?.registers[index] >= piquetes.value.length) {
    return "success"
  } else if (registers?.registers[index] > 0) {
    return "warning"
  } else {
    return "error"
  }
}

// async function getTotalRegisters() {
//   totalRegistersVegetal.value = 0
//   totalRegistersAnimal.value = 0

//   totalRegistersVegetal.value = piquetes.value.length
//   totalRegistersAnimal.value = batches.value.length

//   totalRegistersVegetal.value = await db.registers
//     .where("branch")
//     .equals("vegetal")
//     .count()
//     .catch((error) => {
//       console.log("Error getting total number of registers: ", error)
//     })
//   totalRegistersAnimal.value = await db.registers
//     .where("branch")
//     .equals("animal")
//     .count()
//     .catch((error) => {
//       console.log("Error getting total number of registers: ", error)
//     })
// }

const setBadgeContent = async () => {
  // Check if categoriesList and registersByCategory are arrays
  if (!Array.isArray(categoriesList.value) || !Array.isArray(registersByCategory.value)) {
    console.error("Either categoriesList or registersByCategory is not an array")
    return
  }

  if (registersByCategory.value.length === 0) {
    console.log("registersByCategory is empty")
    return
  }

  badgeContentList.value = []

  // Iterate over categoriesList
  for (let i = 0; i < categoriesList.value.length; i++) {
    const branch = categoriesList.value[i].branch

    registersByCategory.value.forEach((cat) => {
      let badgeCount = 0
      if (cat.categoryCode === categoriesList.value[i].code) {
        for (const register of cat.registers) {
          if (
            register <
            (branch === "vegetal"
              ? piquetes.value.length
              : batches.value.length)
          ) {
            badgeCount++
          }
        }
        badgeContentList.value[i] = badgeCount
      }
    })

    if (categoriesList.value[i].code === category.value.code) {
      recommendedBadgeContent.value = badgeContentList.value[i]
    }
  }
}

const refresh = async () => {
  // await getTotalRegisters()
  await getRegistersByActivity()
  await setBadgeContent()
}

const updateCategory = (newCategory) => {
  category.value = newCategory
  icon.value = newCategory.icon
}
</script>
